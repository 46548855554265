import minus_circle from "../assets/images/icons/minus_red_circle.svg";
import plus_circle from "../assets/images/icons/red_plus.svg";
interface Props {
  name: string;
  address: string;
  icon: string;
  isAdd?: boolean;
}

export const ManItem = (props: Props) => {
  return (
    <div className="man_item my_effect justify-content-between py-2 my-2">
      <div className="d-flex flex-fill">
        <div className="align-self-center">
          <img src={props.icon} alt="" className="man_icon" />
        </div>
        <div className="row flex-fill">
          <div className="col-md-7 align-self-center">
            <h5 className=" fw-normal align-self-center">{props.name}</h5>
          </div>
          <div className="col-md-5 align-self-center">
            <p className="ms-2 align-self-center text-start">{props.address}</p>
          </div>
        </div>
      </div>
      {props.isAdd ? (
        <img src={plus_circle} alt="" className="mx-2" />
      ) : (
        <img src={minus_circle} alt="" className="mx-2" />
      )}
    </div>
  );
};
