import { Alert, Card, Container, Form } from "react-bootstrap";
import { NoFooterLayout } from "../components/Layouts/NoFooterLayout";
import { useNavigate } from "react-router-dom";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { useForm } from "react-hook-form";
import { MemberCodeDto } from "../models";
import { Email, UserInfo } from "../store";
import { useRecoilState, useRecoilValue } from "recoil";
import { useCheckCodeMail, useGSignup } from "../services/Query";
import TokenService from "../services/TokenService";
import { useEffect, useState } from "react";
import ReactLoading  from 'react-loading';
interface FormValues {
    email:string
    code:string
  }
export const CodeLogin = () => {
    const navigate = useNavigate()
    const [alertVariant, setAlertVariant] = useState<string | null>(null);
    const email = useRecoilValue(Email);
    const [userinfo, setUserinfo] = useRecoilState(UserInfo);
    const { mutate: checkCodeMail,isLoading: codeloading, } = useCheckCodeMail();
    const { mutate: onGSignup } = useGSignup();
    const {
        register,
        handleSubmit,
        formState: { errors },

      } = useForm<FormValues>({
        mode: "onChange",
      });
      useEffect(()=>{
        const timer = setTimeout(()=>{
          setAlertVariant('')
        },3000)
        return ()=>clearTimeout(timer)
      },[alertVariant])
      const handleExist = async (emails: string) => {
        const data = {
          Email: emails,
        };
        onGSignup(JSON.stringify(data), {
          onSuccess: (res) => {
            const { member, token } = res;
            TokenService.updateLocalAccessToken(token);
            setUserinfo(member);
            if (!member?.member_FirstName && !member?.member_LastName){
              navigate('/update-user')
            }else{
              navigate("/dashboard");
            }
          },

        });
      };
      const onSubmit = (data:FormValues)=>{
        const objNew: MemberCodeDto = {
            Email: email.Email, 
            Code: data.code
          };
          checkCodeMail(objNew, {
            onSuccess: (response) => {
              if (response) {
                handleExist(email.Email); 
              } else {
                setAlertVariant("danger");

              }
            },
            onError: (error) => {
              console.log({error});
            }
          });
          
      }
      const getAlertMessage = (variant:string | null)=>{
        if(variant=='danger'){
          return "Invalid code"
        }
      }
      const renderLoader = () => (
        <div className="w-100 text-center py-4"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <ReactLoading
            type="spinningBubbles"
            className="mx-auto my-auto"
            height={"70px"}
            width={"70px"}
            color="#666666"
          />
        </div>
      );
  return (
    <NoFooterLayout topClass="box_shadow">
      <div className="signup_wrap">
        <button
          className="back_btn"
          onClick={() => {
            navigate("/login", { replace: true });
          }}
        >
          <img src={white_arrow} alt="" /> back
        </button>
        <Container className="pt-5">
          <Card className="sign_card mx-auto" style={{width:'600px',textAlign:'center'}}>
            <Card.Body>
              <div className="mt-4">
                <Container>
                  <div className="mt-3 mb-3">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    
                    <div  >
                        <span style={{textAlign:'center',fontSize:'18px'}}>
                        Edusfere access is currently available by invitation only. If you have an invite code, please enter it below to continue. Otherwise, click "Join the Waitlist" to secure your spot!
                        </span>
                    </div>
                    
                    <Form.Group className="mb-3 mt-3">
                        <Form.Control
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        {...register("code", {
                            required: "Code is required",
                            pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter only numbers",
                            },
                        })}
                        className="p-3"
                        placeholder="invite code"
                        autoFocus
                        isInvalid={!!errors.code}
                        />
                    </Form.Group>
                    
                    {/* {loading && renderLoader()} */}
                    </Form>
                    <div className="d-flex justify-content-center mb-3">
                        <button
                        onClick={()=>{alert('Google form')}}
                        type="submit"
                        className="border_warn mt-3 back_btn rounded_3 py-3 px-3 fs-6 fnt_robo fw-bold text-white"
                        >
                        join the waitlist
                        </button>
                    </div>
                  </div>
                  {alertVariant && (
                    <Alert variant={alertVariant}>{getAlertMessage(alertVariant)}</Alert>
                  )}
                  {codeloading && renderLoader()}
                </Container>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </NoFooterLayout>
  )
}
