import { MainLayout } from "../components/Layouts/MainLayout";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useGetCollectionInfo } from "../services/Query";
const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
const ViewCollection = () => {
  let { id: folder_id } = useParams();
  const navigate = useNavigate();
  const { data: folderInfo, isLoading } = useGetCollectionInfo(folder_id);

  return (
    <MainLayout>
      <div className="top_blue_action_bar py-2">
        <div className="container">
          <button
            className="back_btn_o"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={white_arrow} alt="" /> back
          </button>
        </div>
      </div>
      <div className="edit_box mx-auto py-3">
        <div className="row gy-3 fnt_robo">
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>folder name</b>
              </Form.Label>
              <h6>{folderInfo?.name}</h6>
            </Form.Group>
          </div>
          {folderInfo?.publisherIcon && (
            <div className="col-12">
              <Form.Group>
                <Form.Label>
                  <b>publisher logo</b>
                </Form.Label>
                <img
                  src={`${backend_baseUrl}/download/${folderInfo?.publisherIcon.file_Path}`}
                  alt=""
                  width={200}
                  className="d-block"
                />
              </Form.Group>
            </div>
          )}
          <div className="col-12">
            <Form.Label className="fw-bold fnt_robo">
              Tags
            </Form.Label>
            {folderInfo?.tags?.map((it, idx) => (
              <div className="d-flex mb-2" key={idx}>
              <div
                className="d-flex align-items-center me-1"
                style={{ width: "25px" }}
              >
                {idx + 1}.
              </div>
              <Form.Control
                type="text"
                className="mt-0"
                key={idx}
                value={it}
              />
            </div>
            ))}
          </div>
          <div className="col-12">
            <b>course description</b>
            <p
              dangerouslySetInnerHTML={{
                __html: folderInfo?.description || "",
              }}
            ></p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default ViewCollection;
