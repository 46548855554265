/**
 * Template Detail view page : /build/template/detail/
 */
import { useState } from "react";
import { MainLayout } from "../components/Layouts/MainLayout";
import drop_down from "../assets/images/icons/drop_down.svg";
import check_icon from "../assets/images/icons/check_icon.svg";
import unbookmarked from "../assets/images/icons/bookmark-unfilled.png";
import bookmarked from "../assets/images/icons/bookmark-filled.png";
import back from "../assets/images/icons/white_left_arrow.svg";
import adapt from "../assets/images/images/adapt.png";
import build from "../assets/images/images/build.png";
import dots from "../assets/images/icons/three_dot.svg";
import print from "../assets/images/icons/black_print.svg";
import man_one from "../assets/images/icons/single_man.svg";
import man_tw from "../assets/images/icons/double_man.svg";
import man_th from "../assets/images/icons/thrd_man.svg";
import recycle from "../assets/images/icons/recycle.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  addClassShow,
  addFolderShow,
  confirmAdaptShow,
  curActivity,
  orgActivityInAdapt,
  selectActivityId,
  UserInfo,
} from "../store";
import { activityTypeArry, SpecStr } from "../models/defaults";
import { Dropdown } from "react-bootstrap";
import { useGetAdaptInfo, usePublishActivity } from "../services/Query";
import HttpService from "../services/HttpServices";
import { Link } from "react-router-dom";
import { isMyBookmarked } from "../services/Utils";
export const TemplateDetail = () => {
  const [isAdapt, setIsAdapt] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean[]>([false, false]);
  const [cActivity, setCurActivity] = useRecoilState(curActivity);
  const [showaddClass, setShowAddClass] = useRecoilState(addClassShow);
  const [showaddFolder, setShowAddFolder] = useRecoilState(addFolderShow);
  const [_activityId, set_activityId] = useRecoilState(selectActivityId);
  const { mutate: onPublish } = usePublishActivity();
  const userinfo = useRecoilValue(UserInfo);
  const [close, setclose] = useState(true);
  const { preview } = useParams();

  const navigate = useNavigate();
  const getActivityType = (): string => {
    if (!cActivity) return "";
    switch (cActivity.activity_Type) {
      case "drill":
        return "drill";
      case "game":
        return "game";
      case "song/music":
        return "song";
      case "worksheet":
        return "worksheet";
      case "communicative task":
        return "communicative";
      case "presentation":
        return "presentation";
      case "project":
        return "project";
      case "prompt":
        return "prompt";
      default:
        break;
    }
    return "communicative";
  };

  const [confirmAdapt, setconfirmAdaptShow] = useRecoilState(confirmAdaptShow);
  const [orgActivity, setOrgActivity] = useRecoilState(orgActivityInAdapt);
  const { data: adaptInfo } = useGetAdaptInfo(
    cActivity
      ? cActivity.orgActivity
        ? cActivity.orgActivity.activity_Id
        : cActivity.activity_Id
      : undefined
  );

  return (
    <MainLayout>
      <div className="d-flex bg_selected py-2">
        <button
          className="back_btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={back} alt="" /> back
        </button>
        <h4 className="text-center w-100 fnt_robo">
          {preview === "1"
            ? "this is a preview of how your activity will appear"
            : ""}
        </h4>
      </div>
      <div className="d-flex">
        <div className="left_menu">
          <h5 className="mt-4 text-center button">Template</h5>
          {adaptInfo && adaptInfo.orgTemplate && (
            <div>
              <div className="org_members ps-2 py-1">
                <span>{adaptInfo.orgTemplate.creator?.member_NickName}</span>
                <span className="ms-3">
                  - {adaptInfo.orgTemplate.creator?.member_Country}
                </span>
                {isMyBookmarked(userinfo?.member_Id, adaptInfo.orgTemplate) && (
                  <img
                    src={bookmarked}
                    alt=""
                    className="bookmarked"
                    height={30}
                  />
                )}
              </div>
            </div>
          )}
          <h5 className="mt-4 text-center button">ADAPTATIONS</h5>
          <div>
            {adaptInfo &&
              adaptInfo.results &&
              adaptInfo.results.length > 0 &&
              adaptInfo.results.map((it, idx) => (
                <div className="org_members ps-2 py-1 mb-2" key={idx}>
                  <span>{it?.creator?.member_NickName}</span>
                  <span className="ms-3">- {it?.creator?.member_Country}</span>
                  {isMyBookmarked(userinfo?.member_Id, it) && (
                    <img
                      src={bookmarked}
                      alt=""
                      className="bookmarked"
                      height={30}
                    />
                  )}
                </div>
              ))}
            {adaptInfo && adaptInfo.results && adaptInfo.results.length > 0 && (
              <div className="text-center mb-3 ">
                <Link
                  to={`/adaptations/${adaptInfo.orgTemplate.activity_Id}`}
                  className="text_info text-decoration-none"
                >
                  view all adaptations
                </Link>
              </div>
            )}
          </div>

          <h5
            className="button"
            onClick={() => {
              const openStatus = [false, false];
              openStatus[1] = !isOpen[1];
              setIsOpen(openStatus);
            }}
          >
            POSSIBLE CATEGORIES <img src={drop_down} alt="" />
          </h5>
          <ul className={`ps-0 pt-0 ms-0 ${isOpen[1] ? "" : "d-none"}`}>
            <li>activity type</li>
            <li>
              {activityTypeArry.map((it, idx) => (
                <span
                  key={idx}
                  className={`btn-box  my-1 ${
                    cActivity?.activity_Type
                      .split(SpecStr.split_common)
                      .includes(it)
                      ? "selected"
                      : ""
                  }`}
                >
                  {it}
                </span>
              ))}
            </li>
          </ul>
        </div>
        <div className="dash_content flex-fill">
          <div className="d-flex ps-xl-5 ms-3">
            <div
              className={`dashboard_box p-3 ms-1 d-flex flex-column ${getActivityType()}`}
            >
              <div className="flex-fill d-flex">
                <h3 className="flex-fill">{cActivity?.activity_Name}</h3>
                {preview && cActivity?.activity_Status && (
                  <img
                    src={
                      isMyBookmarked(userinfo?.member_Id, cActivity)
                        ? bookmarked
                        : unbookmarked
                    }
                    className="tip_icon cursor"
                    alt=""
                    onClick={async () => {
                      if (cActivity && userinfo) {
                        try {
                          const it = await HttpService.toggleBookmarked(
                            cActivity.activity_Id,
                            userinfo.member_Id
                          );
                          if (it) setCurActivity(it);
                        } catch (ex) {}
                      }
                    }}
                    height={61}
                  />
                )}
              </div>
              <div className="row details">
                <div className="col-md-6 d-flex align-self-end">
                  <div>
                    <b>{cActivity?.activity_PrepTime}</b>
                    <span className="ms-1">prep</span>
                    <b className="ms-2">{cActivity?.activity_UseTime}</b>
                    <span className="ms-2">class time</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-end">
                    <b>submitted</b> by {cActivity?.creator?.member_FirstName}{" "}
                    {cActivity?.creator?.member_LastName}
                    <img src={check_icon} alt="" />
                  </div>
                  {isAdapt && (
                    <div>
                      adaptation by Chris Goss
                      <img src={check_icon} alt="" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex buttons flex-column align-self-center">
              <img
                src={adapt}
                width={32}
                className="cursor"
                alt=""
                onClick={() => {
                  if (cActivity) {
                    setOrgActivity(cActivity);
                    setconfirmAdaptShow(true);
                  }
                }}
              />
              <img
                src={build}
                width={30}
                alt=""
                className="cursor"
                onClick={() => {
                  setCurActivity(undefined);
                  navigate("/build/template");
                }}
              />
              {preview !== "1" && (
                <Dropdown className="activity_menu">
                  <Dropdown.Toggle>
                    <img src={dots} alt="" className="cursor" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      as="li"
                      className="cursor"
                      onClick={() => {
                        if (cActivity) {
                          set_activityId(cActivity.activity_Id);
                          setShowAddClass(true);
                        }
                      }}
                    >
                      ADD TO CLASS
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="li"
                      className="cursor"
                      onClick={() => {
                        if (cActivity) {
                          set_activityId(cActivity.activity_Id);
                          setShowAddFolder(true);
                        }
                      }}
                    >
                      ADD TO FOLDER
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {/* <img src={dots} width={10} alt="" className="ms-2" /> */}
              <img src={print} width={30} alt="" className="d-none" />
            </div>
          </div>
          <div className="ms-3 mt-3 ps-xl-5 d-none">
            <img src={man_one} alt="" />
            <img src={man_tw} alt="" className="ms-2" />
            <img src={man_th} alt="" className="ms-2" />
            <img src={recycle} alt="" className="ms-2" />
          </div>

          <div className="ms-3 mt-3 ps-xl-5 template_wrap">
            <h5>Description</h5>
            <p>{cActivity?.activity_Desc}</p>

            <div className="row mx-0">
              <div className="col-xl-6 ps-lg-0 mt-lg-4 ps-xl-1 mt-xl-0">
                <h5>Files</h5>
                <div>
                  {cActivity?._attachFiles &&
                    cActivity?._attachFiles.slice(0, 2).map((it, idx) => (
                      <a
                        className="px-2 me-2 span_item files cursor mb-2"
                        key={idx}
                        target="_blank"
                        href={`https://drive.google.com/file/d/${it.file_Path}/view`}
                        rel="noreferrer"
                      >
                        {it.file_Name}
                      </a>
                    ))}
                  {(cActivity?._attachFiles === undefined ||
                    cActivity._attachFiles == null ||
                    cActivity?._attachFiles.length === 0) && (
                    <span className="ms-2 text-muted">none</span>
                  )}
                </div>
              </div>
            </div>

            {cActivity?.additionNote && (
              <div className="mt-4">
                <h5>additional notes</h5>
                <p className="pe-4">{cActivity?.additionNote}</p>
              </div>
            )}

            {preview == "1" && cActivity && (
              <div className="d-flex justify-content-center mt-4">
                <button
                  type="button"
                  name="publish"
                  className="publish_btn  py-2"
                  onClick={() => {
                    if (cActivity) {
                      onPublish(cActivity.activity_Id, {
                        onSuccess: (activity) => {
                          setCurActivity(activity);
                          navigate("/build/public/activity");
                        },
                      });
                    }
                  }}
                >
                  publish
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
