import { useEffect, useRef, useState } from "react";
import { MainLayout } from "../components/Layouts/MainLayout";
import folder from "../assets/images/icons/folder_icon.svg";
import pen from "../assets/images/icons/edit_pen.svg";
import edit_close from "../assets/images/icons/edit_close.svg";
import back_btn from "../assets/images/images/back_arrow@2x.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  confirmAdaptShow,
  curActivity,
  curClass,
  orgActivityInAdapt,
  showUnverified,
  UserInfo,
} from "../store";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import HttpService from "../services/HttpServices";
import { LeftFolder } from "../components/LeftFolder";
import {
  useClassActivities,
  useClassLessons,
  useDeleteActivity,
  useDeleteLesson,
  useGetClass,
  useMemberInfo,
} from "../services/Query";
import { ActivityBox } from "../components/ActivityBox";
import list_icon from "../assets/images/images/list.png";
import ReactLoading from "react-loading";
import { ConfirmModal } from "../components/modals/ConfirmModal.server";
import { MessageString } from "../models/defaults";
const Label = styled.span`
  display: inline-block;
  width: 100px;
`;
/**
 *
 * class detail page : /classdetail
 */
export const ClassDetail = () => {
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const [cur_class, set_cur_class] = useRecoilState(curClass);
  const [showModal, setshowModal] = useState<boolean>(false);
  const [deleteMsg, setdeleteMsg] = useState("");
  const [deleteShow, setdeleteShow] = useState(false);

  const setCurActivity = useSetRecoilState(curActivity);
  const [confirmAdapt, setconfirmAdaptShow] = useRecoilState(confirmAdaptShow);
  const [orgActivity, setOrgActivity] = useRecoilState(orgActivityInAdapt);
  let { clsId } = useParams();
  const [showVerified, setShowVerified] = useRecoilState(showUnverified);
  const { data: userData } = useMemberInfo(userinfo?.member_Id);
  const { data: clsInfo, isFetching, refetch: getClsInfo } = useGetClass(clsId);
  const {
    isFetching: isLoadingAvts,
    data: activities,
    refetch: getActivities,
  } = useClassActivities(clsId);
  const { data: lessons, refetch: getLessons } = useClassLessons(clsId);
  useEffect(() => {
    if (clsId) {
      getClsInfo();
      getActivities();
    }
  }, [clsId]);

  const navigate = useNavigate();
  const onDeleteClass = async () => {
    await HttpService.deleteClass(clsId);
    if (userinfo?.member_Id) {
      const user = await HttpService.getUserInfo(userinfo.member_Id);
      if (user) setUserinfo(user);
    }
    setshowModal(false);
    navigate(-1);
  };
  const { mutate: onDeleteActivity } = useDeleteActivity();
  const { mutate: onDeleteLesson } = useDeleteLesson();

  const deleteId = useRef<string>("");
  const deleteFlag = useRef<boolean>(false);
  const deleteTemplate = (activityId: string) => {
    deleteId.current = activityId;
    deleteFlag.current = false;
    setdeleteMsg(MessageString.deleteActivity);
    setdeleteShow(true);
  };
  const isDraftShow = (): boolean => {
    if (
      clsInfo &&
      activities &&
      activities.filter((it) => it.isDraft).length > 0
    )
      return true;
    else {
      if (lessons && lessons.filter((it) => it.isDraft).length > 0) return true;
    }
    return false;
  };
  return (
    <MainLayout>
      <div className="d-flex">
        <div className="left_menu">
          <h5 className="text-center mt-3">
            DESCRIPTION
            <img
              src={pen}
              alt=""
              className="ms-2"
              onClick={() => {
                navigate(`/build/classedit/${clsId}`);
              }}
            />
          </h5>
          <div className="ms-3">
            <Label>student:</Label>
            <b>{clsInfo?.class_ClassSize}</b>
          </div>
          <div className="ms-3">
            <Label>ages:</Label>
            <b>{clsInfo?.class_AgeRange}</b>
          </div>
          <div className="ms-3">
            <Label>proficiency level:</Label>
            <b>{clsInfo?.class_ProficiencyLevel}</b>
          </div>
          <div className="ms-3">
            <Label>energy:</Label>
            <b>{clsInfo?.class_EnegryLevel}</b>
          </div>
          <div className="ms-3">
            <Label>location:</Label>
            <b>
              {clsInfo?.class_Country} {clsInfo?.class_City}
            </b>
          </div>
          <LeftFolder />
        </div>
        <div className="dash_content ps-3">
          <div className="d-flex justify-content-between">
            <button
              className="border-0 bg-body ms-3"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={back_btn} alt="" width={125} />
            </button>
          </div>

          <>
            <div className="ms-4 mt-4">
              <h6 className="fw-light">Class page</h6>
              <h5 className="fs-3">
                {clsInfo?.class_Title}
                <img
                  src={pen}
                  alt=""
                  className="ms-2 cursor"
                  onClick={() => {
                    navigate(`/build/classedit/${clsId}`);
                  }}
                />
                <img
                  src={edit_close}
                  alt=""
                  height={43}
                  className="mt-1 cursor"
                  onClick={() => {
                    setshowModal(true);
                  }}
                />
              </h5>
            </div>

            <div className="ms-4 mt-1">
              <button
                className="plus_btn ms-0"
                onClick={() => {
                  if (clsId) {
                    if (userData) {
                      setCurActivity(undefined);
                      // if (userData.member_Type != userLevel.contributor) {
                      //   setShowVerified(true);
                      // } else {
                      set_cur_class(clsId);
                      navigate("/build/template");
                      // }
                    }
                  }
                }}
              >
                add activity
              </button>
              <button
                className="plus_btn ms-2"
                onClick={() => {
                  if (clsId) {
                    set_cur_class(clsId);
                    navigate("/build/lesson");
                  }
                }}
              >
                add lesson
              </button>
              <button
                className="plus_btn ms-2"
                onClick={() => {
                  navigate(`/build/foldernew/${clsId}/new`);
                }}
              >
                add folder
              </button>
            </div>
            <div
              className={`template_list  ms-4 ps-0 ${
                isDraftShow() ? "mt-3" : ""
              }`}
            >
              <h4 className={`${isDraftShow() ? "" : "d-none"}`}>DRAFTS</h4>
              <div
                className={`container  px-0 mx-0 ${
                  isDraftShow() ? "mt-3" : ""
                }`}
              >
                <div className="cell_box row ps-0 mx-0">
                  {isLoadingAvts ? (
                    <div className="w-100 text-center py-4 ">
                      <ReactLoading
                        type="spinningBubbles"
                        className="mx-auto my-auto"
                        height={"80px"}
                        width={"80px"}
                        color="#666666"
                      />
                    </div>
                  ) : (
                    activities &&
                    activities
                      .filter((it) => it.isDraft)
                      .map((it) => (
                        <div
                          className="col-xl-4 col-lg-6 mb-md-4 ps-0"
                          key={it.activity_Id}
                        >
                          <ActivityBox
                            icon={list_icon}
                            activity={it}
                            disabledLesson={false}
                            onDelete={deleteTemplate}
                            onAdapt={() => {
                              setOrgActivity(it);
                              setconfirmAdaptShow(true);
                            }}
                          />
                        </div>
                      ))
                  )}
                </div>
              </div>
            </div>

            <div
              className={`template_list ms-4 ps-0 ${
                clsInfo?._Folders && clsInfo?._Folders.length > 0
                  ? "mt-3"
                  : "mt-0"
              }`}
            >
              <h4>
                {clsInfo?._Folders && clsInfo?._Folders.length > 0 && `FOLDERS`}
              </h4>
              <div className="container ms-0 mx-0">
                <div className="cell_box row ps-3">
                  {isFetching ? (
                    <div className="w-100 text-center py-4 ">
                      <ReactLoading
                        type="spinningBubbles"
                        className="mx-auto my-auto"
                        height={"80px"}
                        width={"80px"}
                        color="#666666"
                      />
                    </div>
                  ) : (
                    clsInfo?._Folders?.map((it) => (
                      <div
                        className="cell folder button_shadow col-md-4"
                        key={it.folder_Id}
                        onClick={() => {
                          navigate(`/folderdetail/${it.folder_Id}`);
                        }}
                      >
                        <img src={folder} alt="" />
                        <span className="ms-3 fw-bold">{it.folder_name}</span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <div
              className={`template_list  ms-4 ps-0 ${
                clsInfo && activities && activities.length > 0 && "mt-3"
              }`}
            >
              <h4>
                {clsInfo && activities && activities.length > 0 && `ACTIVITIES`}
              </h4>
              <div className="container mt-3 ps-0 mx-0">
                <div className="cell_box row ps-0 ms-0">
                  {isLoadingAvts ? (
                    <div className="w-100 text-center py-4">
                      <ReactLoading
                        type="spinningBubbles"
                        className="mx-auto my-auto"
                        height={"80px"}
                        width={"80px"}
                        color="#666666"
                      />
                    </div>
                  ) : (
                    activities
                      ?.filter((it) => !it.isDraft)
                      .map((it) => (
                        <div
                          className="col-xl-4 col-lg-6 mb-md-4 ps-0"
                          key={it.activity_Id}
                        >
                          <ActivityBox
                            icon={list_icon}
                            activity={it}
                            disabledLesson={false}
                            onDelete={deleteTemplate}
                            onAdapt={() => {
                              setOrgActivity(it);
                              setconfirmAdaptShow(true);
                            }}
                          />
                        </div>
                      ))
                  )}
                </div>
              </div>
            </div>
            <div
              className={`template_list  ms-4 ps-0 ${
                lessons && lessons.length > 0 && "mt-3"
              }`}
            >
              <h4>{lessons && lessons.length > 0 && "LESSONS"}</h4>
              <div className="container mt-3 mx-0 ps-0"></div>
            </div>
          </>
        </div>
      </div>
      <ConfirmModal
        show={showModal}
        leftTitle={"cancel"}
        rightTitle={"confirm"}
        content={"Are you sure you want to delete this class?"}
        onOk={() => {
          setshowModal(false);
        }}
        onDiscard={() => {
          onDeleteClass();
        }}
      />

      <ConfirmModal
        content={deleteMsg}
        show={deleteShow}
        leftTitle={"no"}
        rightTitle={"yes"}
        onDiscard={() => {
          if (deleteFlag.current && userinfo) {
            onDeleteLesson(
              { memberId: userinfo.member_Id, lessonId: deleteId.current },
              {
                onSuccess: () => {
                  getLessons();
                  setdeleteShow(false);
                },
              }
            );
          } else {
            onDeleteActivity(deleteId.current, {
              onSuccess: () => {
                getClsInfo();
                setdeleteShow(false);
              },
            });
          }
        }}
        onOk={() => {
          setdeleteShow(false);
        }}
      />
    </MainLayout>
  );
};
