import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Alert, Card, Container } from "react-bootstrap";
import { NoFooterLayout } from "../components/Layouts/NoFooterLayout";
import { AuthForm, FormData } from "../components/AuthFormLogin";
import { useCheckCodeMail, useGSignup, useSendEmail, useSendEmailAdmin } from "../services/Query";
import { MemberCodeDto } from "../models";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Email, UserInfo } from "../store";
import TokenService from "../services/TokenService";
import { AxiosError } from "axios";
import HttpService from "../services/HttpServices";

interface tokenType {
  token: string;
}
export const LoginWithEmail = () => {
  const navigate = useNavigate();
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  // const { isLoading: loading, mutate: SendEmail } = useSendEmail();
  const {isLoading: loading,  mutate: SendEmailAdmin } = useSendEmailAdmin();

  const { mutate: onGSignup } = useGSignup();
  const setEmail = useSetRecoilState(Email);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const handleExist = async (email: string) => {
    const data = {
      Email: email,
    };
    onGSignup(JSON.stringify(data), {
      onSuccess: (res) => {
        const { member, token } = res;
        TokenService.updateLocalAccessToken(token);
        setUserinfo(member);
        if (!member?.member_FirstName && !member?.member_LastName){
          navigate('/update-user')
        }else{
          navigate("/dashboard");
        }
      },
      onError: (err) => {
        const res = err as AxiosError;
        const tokenObj = res.response?.data as tokenType;
        if (tokenObj) {
          HttpService.verifyUser(tokenObj.token).then((res) => {
            setUserinfo(res.data);
          });
        }
      },
    });
  };
  const onSubmit = async (data: FormData) => {
    if ( data.email) {
      SendEmailAdmin(data.email, {
        onSuccess: (res) => {
          if (res) {
            handleExist(data.email);
          } else {
            setEmail({
              Email: data.email, 
            });
            navigate('/invite-code')
          }
        },
        onError: (error) => {
          console.log({error})
        },
      });
    
    } 
  };
  return (
    <NoFooterLayout topClass="box_shadow">
      <div className="signup_wrap">
        <button
          className="back_btn"
          onClick={() => {
            navigate("/login", { replace: true });
          }}
        >
          <img src={white_arrow} alt="" /> back
        </button>
        <Container className="pt-5">
          <Card className="sign_card mx-auto">
            <Card.Header>Sign in with Email</Card.Header>
            <Card.Body>
              <div className="mt-4">
                <Container>
                  <div className="mt-3 mb-3">
                    <AuthForm
                      loading={ loading  }
                      onSubmit={onSubmit}
                      register={register}
                      handleSubmit={handleSubmit}
                      errors={errors}
                    />
                  </div>
                </Container>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </NoFooterLayout>
  );
};
