import { useNavigate } from "react-router-dom";
import { NoFooterLayout } from "../components/Layouts/NoFooterLayout";
import { Card, Container, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import  ReactLoading  from 'react-loading';
import { useRecoilValue } from "recoil";
import { UserInfo } from "../store";
import { useCountries, useMemberInfo, useProfileData } from "../services/Query";
import { useEffect } from "react";
import { UpdateInfoUser } from "../models";
interface Props {
    setUserInfo: (userInfo: any) => void;
  }
  interface FormValues {
      firstName: string;
      lastName: string;
      schoolName: string;
      schoolType: string;
      country: string;
      province:string;
      city:string;
    }
  export const typeSchoolArry = [
    "Public School",
    "Private School",
    "International School",
    "College/University",
    "Homeschool",
    "Microschool",
    "Other"
  ];
export const CheckUserInfo = (props: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        watch,
      } = useForm<FormValues>({
        mode: "onChange",
      });
      const navigate = useNavigate()
      const userinfo = useRecoilValue(UserInfo);
      const { mutate: onUpdateprofile ,isLoading:updateLoading} = useProfileData();
      const { data: Countries } = useCountries();
      const memberID = String(userinfo?.member_Id);
      const { data: memberData, refetch:getMemberData } = useMemberInfo(memberID);
      const watchTypeOfSchool = watch("schoolType");
      const watchCountry = watch("country");

      console.log(memberData?.member_Province)
      useEffect(() => {
        if (memberData) {
          setValue("firstName", "");
          setValue("lastName", "");
          setValue("schoolType", memberData?.member_SchoolType);
          setValue("schoolName", memberData?.member_SchoolName);
          setValue("country", memberData?.member_Country);
          setTimeout(() => {
            setValue("province", memberData?.member_Province);
          }, 0);
          setValue("city",memberData?.member_City)
        }
      }, [memberData]);
      const getStates = (countryName: string | undefined): string[] => {
        if (Countries) {
          const country = Countries.filter((it) => it.name === countryName);
          if (country && country.length) {
            const sts = country[0].states.map((it) => it.name);
            return sts;
          }
        }
        return [];
      };
      const onSubmit: SubmitHandler<UpdateInfoUser> = async (data) => {
        const formData: FormValues = {
          firstName: data.firstName ?? "",
          lastName: data.lastName ?? "",
          schoolType: data.schoolType ?? "",
          schoolName: data.schoolName ?? "",
          country: data.country ?? "",
          province:data.province ??"",
          city:data.city ??""
    
        };
        if (memberData) {
          onUpdateprofile(
            { memberId: memberID, formData: JSON.stringify(formData) },
            {
              onSuccess: (res) => {
                props.setUserInfo(res);
                getMemberData();
                navigate('/dashboard')
              },
            }
          );
        }
      };
      const renderLoader = () => (
        <div className="w-100 text-center py-4"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <ReactLoading
            type="spinningBubbles"
            className="mx-auto my-auto"
            height={"70px"}
            width={"70px"}
            color="#666666"
          />
        </div>
      );
  return (
    <NoFooterLayout topClass="box_shadow">
      <div className="signup_wrap">
        <Container className="pt-5">
          <Card className="sign_card mx-auto">
            <Card.Header>Update Information</Card.Header>
            <Card.Body>
              <div className="mt-4">
                <Container>
                  <div className="mt-3 mb-3">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                    <Form.Label className="fw-bold form-label">
                        First Name *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        {...register("firstName", {
                        required: true,
                        validate: (value) => value.trim().length >= 3,
                        })}
                        isInvalid={!!errors.firstName}
                        autoFocus
                    />
                    {errors.firstName && (
                        <small className="text-danger">
                        First name must be at least 3 characters long.
                        </small>
                    )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label className="fw-bold form-label">
                        Last Name *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        {...register("lastName", {
                        required: true,
                        validate: (value) => value.trim().length >= 3,
                        })}
                        isInvalid={!!errors.lastName}
                    />
                    {errors.lastName && (
                        <small className="text-danger">
                        Last name must be at least 3 characters long.
                        </small>
                    )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label className="fw-bold form-label">
                        School Type *
                    </Form.Label>
                    <Form.Select {...register("schoolType")}>
                        {typeSchoolArry.map((it, idx) => (
                        <option value={it} key={idx}>
                            {it}
                        </option>
                        ))}
                    </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label className="fw-bold form-label">
                        School Name *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        {...register("schoolName", {
                        required: true,
                        validate: (value) => value.trim().length >= 3,
                        })}
                        isInvalid={!!errors.schoolName}
                    />
                    {errors.schoolName && (
                        <small className="text-danger">
                        School name must be at least 3 characters long.
                        </small>
                    )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label className="fw-bold form-label">Country *</Form.Label>
                    <Form.Select {...register("country")}>
                        {Countries &&
                        Countries.map((it, idx) => (
                            <option value={it.name} key={idx}>
                            {it.name}
                            </option>
                        ))}
                    </Form.Select>
                    </Form.Group>
                    
                    <Form.Group className="mb-3">
                    <Form.Label className="fw-bold form-label">
                    State / Province *
                    </Form.Label>
                    <Form.Select {...register("province")}>
                        <option value=""></option>
                        {getStates(watch("country")).map((it, idx) => (
                        <option value={it} key={idx}>
                            {it}
                        </option>
                        ))}
                    </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                    <Form.Label className="fw-bold form-label">
                        City *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        {...register("city", {
                        required: true,
                        validate: (value) => value.trim().length >= 3,
                        })}
                        isInvalid={!!errors.schoolName}
                    />
                    {errors.schoolName && (
                        <small className="text-danger">
                        city must be at least 3 characters long.
                        </small>
                    )}
                    </Form.Group>
                    <div className="d-flex justify-content-center gap-3">
                    <button
                        type="submit"
                        style={{color:'black'}}
                        disabled={!isValid || !watchTypeOfSchool || !watchCountry}
                        className="border_warn mt-3 back_btn rounded_3 py-3 px-3 fs-6 fnt_robo fw-bold text-white">
                        Save
                    </button>
                    </div>
                    {updateLoading && renderLoader()}
                </Form>
                  </div>

                </Container>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </NoFooterLayout>
  );
};
