import { MainLayout } from "../components/Layouts/MainLayout";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import plugins from "suneditor/src/plugins";
import { Container as ContainerDrag, Draggable } from "react-smooth-dnd";
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';s
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteUnit,
  useGetCourseInfo,
  useGetUnitInfo,
  useSaveUnit,
} from "../services/Query";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import minus_icon from "../assets/images/icons/red_minus.svg";
// import htmlToDraft from 'html-to-draftjs';
// import { ContentState, EditorState } from 'draft-js';
import plus_icon from "../assets/images/icons/circle_plus_icon.svg";
import ReactLoading from "react-loading";
import { ConfirmRemoveModal } from "../components/modals/ConfirmRemoveModal";
import SunEditor from "suneditor-react";
import { ConfirmModal } from "../components/modals/ConfirmModal.server";
type UnitFrm = {
  id?: string;
  course_id: string;
  order: number;
  name: string;
  keyIdeas: string;
  keyIdea: string[];
  description: string;
  companion: string;
  pages: string;
};
const EditUnit = () => {
  let { course_id, unit_id } = useParams();
  const navigate = useNavigate();
  const { data: unitInfo, isLoading } = useGetUnitInfo(unit_id);
  const { data: courseInfo, isLoading: isLoadingCourse } =
    useGetCourseInfo(course_id);
  const { mutate: onSaveUnit, isLoading: isSaving } = useSaveUnit();
  const [keyIdeas, setKeyIdeas] = useState<string[]>(unitInfo?.keyIdea || [""]);
  const { mutate: deleteItem, isLoading: isDeleting } = useDeleteUnit();
  const [removeShow, setRemoveShow] = useState(false);
  const [removeKeyShow, setRemoveKeyShow] = useState(false);
  const refKeyIdeasTab = useRef<number>(0);

  const [showMsg, setShowMsg] = useState("");
  const {
    register,
    setValue,
    watch,
    reset,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = useForm<UnitFrm>({ mode: "onChange" });
  useEffect(() => {
    if (unitInfo) {
      reset({
        id: unitInfo.id,
        name: unitInfo.name,
        course_id: unitInfo.unit_Course_Id || "",
        keyIdeas: unitInfo.keyIdeas || "",
        keyIdea: unitInfo.keyIdea || [],
        description: unitInfo.description,
        companion: unitInfo.companion,
        pages: unitInfo.pages,
        order: unitInfo.order,
        // keyIdeas : unitInfo.keyIdeas,
      });
    } else {
      reset({
        name: "",
        keyIdeas: "",
        keyIdea: [],
        course_id: course_id,
        description: "",
        companion: "",
        pages: "",
        order: undefined,
      });
    }
    trigger();
  }, [unitInfo]);

  useEffect(() => {
    const nextfield = document.querySelector(
      `input[name=key-ideas-${refKeyIdeasTab.current}]`
    );

    if (nextfield !== null) {
      (nextfield as HTMLElement).focus();
    }
  }, [keyIdeas])
  // const contentState = useMemo(() => {
  //   if (unitInfo) {
  //     const contentBlock = htmlToDraft(unitInfo?.description || '');
  //     if (contentBlock) {
  //       const contentState = ContentState.createFromBlockArray(
  //         contentBlock.contentBlocks
  //       );
  //       const editorState = EditorState.createWithContent(contentState);
  //       return editorState;
  //     }
  //   }
  //   return undefined;
  // }, [unitInfo]);
  const onSave = () => {
    const values = getValues();
    values.keyIdea = keyIdeas;
    onSaveUnit(JSON.stringify(values), {
      onSuccess: (res) => {
        navigate(-1);
      },
      onError: () => {},
    });
  };
  const removeId = useRef<number | undefined>(undefined);
  const onConfirmDelete = () => {
    const msg = `Are you sure to delete the <b>${unitInfo?.name}</b>?`;
    setShowMsg(msg);
    setRemoveShow(true);
  };

  const onDropItem = (e: any) => {
    const currentPos = e.removedIndex;
    const newPos = e.addedIndex;
    const result = [...keyIdeas];
    let itemToAdd = result[currentPos];
    if (currentPos !== null) {
      itemToAdd = result.splice(currentPos, 1)[0];
    }
  
    if (newPos !== null) {
      result.splice(newPos, 0, itemToAdd);
    }

    setKeyIdeas(result);
  };


  if (isLoading || isLoadingCourse) {
    return (
      <MainLayout>
        <div className="d-flex justify-content-center">
          <ReactLoading
            type={"bubbles"}
            color={"#00B1E1"}
            height={30}
            width={375}
          />
        </div>
      </MainLayout>
    );
  }
  return (
    <MainLayout>
      <div className="top_blue_action_bar  py-2 d-flex justify-content-center fnt_robo">
        <button
          className="back_btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={white_arrow} alt="" /> back
        </button>
        <h4 className="title">{unitInfo ? "save unit" : "add unit"}</h4>
      </div>
      <div className="edit_box mx-auto py-3">
        <div className="row">
          <div className="col-6">
            <Form.Group className="mb-3 fnt_robo">
              <Form.Label>
                <b>unit name *</b>
                <span className="d-block">the title of your unit</span>
              </Form.Label>
              <Form.Control
                type="text"
                {...register("name", { required: true })}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Group className="mb-3 fnt_robo">
              <Form.Label>
                <b>key ideas * </b>
                <span className="d-block">
                  input at least one key idea addressed in the unit
                </span>
              </Form.Label>
                <ContainerDrag 
                     onDrop={(e) => onDropItem(e)}
                     render={(ref) => {
                      return (
                        <div className="key-ideas" ref={ref}>
                          {keyIdeas.map((it, idx) => {
                            return (
                              <Draggable 
                                key={idx}
                                render={() => (
                                  <div className="d-flex mb-2" key={idx}>
                                  <div
                                    className="d-flex align-items-center me-1"
                                    style={{ width: "25px" }}
                                  >
                                    <i className="fas fa-grip-vertical"></i>
                                  </div>
                                  <Form.Control
                                    type="text"
                                    className="mt-0"
                                    name={`key-ideas-${idx}`}
                                    style={{ width: "80%", boxShadow: "none" }}
                                    key={idx}
                                    value={it}
                                    onChange={(e) => {
                                      refKeyIdeasTab.current = idx;
                                      const newItem = [...keyIdeas];
                                      newItem[idx] = e.target.value;
                                      setKeyIdeas(newItem);
                                    }}
                                    onKeyDown ={(e) => {
                                      if (e.key == "Enter") {
                                        const nwItems = [...keyIdeas];
                                        nwItems.push("");
                                        setKeyIdeas(nwItems);
                                        refKeyIdeasTab.current = idx + 1;
                                      }
                                    }}
                                  />
                                  <div style={{ width: "25px" }}>
                                    {idx > 0 && (
                                      <img
                                        src={minus_icon}
                                        alt=""
                                        className="ms-2"
                                        onClick={() => {
                                          removeId.current = idx;
                                          setRemoveKeyShow(true);
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                )}
                              />
                            )
                          })}
                        </div>
                      )
                    }}
                />
             
            </Form.Group>
            <div className="d-flex justify-content-center">
              <img
                src={plus_icon}
                alt=""
                className="cursor"
                onClick={() => {
                  const nwItems = [...keyIdeas];
                  nwItems.push("");
                  setKeyIdeas(nwItems);
                  // setRemoveKeyShow(true);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Label className="fw-bold fnt_robo">
              companion printed title(s)
            </Form.Label>
            <div className="row fnt_robo">
              <div className="col-7">
                <InputGroup className="mb-3">
                  <Form.Select {...register("companion")}>
                    <option></option>
                    {courseInfo?.companions?.map((it, idx) => (
                      <option value={it.title} key={idx}>
                        {it.title}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </div>
              <div className="col">
                <InputGroup className="mb-3">
                  <InputGroup.Text>pages or page range</InputGroup.Text>
                  <Form.Control type="text" {...register("pages")} />
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
        <div>
          <b className=" fnt_robo">unit description</b>
          <SunEditor
            name="preparation_val"
            defaultValue={unitInfo?.description || ""}
            setOptions={{
              height: 'auto', 
              minHeight: '250px',
              plugins: plugins,
              buttonList: [
                ["undo", "redo"],
                ["formatBlock"],
                ["blockquote"],
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                ],
                ["table", "list", "link", "image", "video"],
              ],
            }}
            onChange={(content) => {
              setValue("description", content);
              // onChange(content, nIndex);
            }}
            // onChange={(content: string) => {
            //   // if (nValue != content) onChange(content, nIndex);
            // }}
            // onBlur={(event: any, content: string) => {
            //   if (nValue != content) onChange(content, nIndex);
            // }}
          />
          {/* <SunEditor
            height={"250"}
            name="preparation_val"
            defaultValue={unitInfo?.description || ""}
            onChange={(content) => {
              setValue("description", content);
              // onChange(content, nIndex);
            }}
          /> */}
          {/* <Editor
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'fontFamily',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'emoji',
                'remove',
                'history',
              ],
            }}
            defaultEditorState={contentState}
            wrapperClassName="demo-wrapper draft_wrapper"
            onChange={(ev) => {
              const strVal = draftToHtml(ev);
              setValue('description', strVal);
              // onChange(strVal, nIndex);
            }}
            editorClassName="demo-editor"
          /> */}
        </div>
        <div className="row mt-3 fnt_robo">
          <div className="col-4">
            <InputGroup>
              <InputGroup.Text className="fw-bold">unit number *</InputGroup.Text>
              <Form.Control
                type="number"
                {...register("order", { required: true })}
              />
            </InputGroup>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          {unitInfo && (
            <button
              className="remove_btn me-3"
              disabled={!isValid}
              onClick={() => onConfirmDelete()}
            >
              delete unit
            </button>
          )}
          <button
            className="save_btn"
            disabled={!isValid}
            onClick={() => onSave()}
          >
            {isSaving && (
              <Spinner animation="border" size={"sm"} className="me-1" />
            )}
            {unitInfo ? "save unit" : "add unit"}
          </button>
        </div>
      </div>
      {removeShow && (
        <ConfirmRemoveModal
          show={removeShow}
          isDeleting={isDeleting}
          onDiscard={() => {
            if (unitInfo?.id) {
              deleteItem(unitInfo?.id, {
                onSuccess: () => {
                  setRemoveShow(false);
                  navigate(`/dashboard/course/${unitInfo._Course.course_Id}`);
                },
              });
            }
          }}
          onOk={() => {
            setRemoveShow(false);
          }}
          message={showMsg}
        />
      )}
      <ConfirmModal
        show={removeKeyShow}
        content="Are you sure you want to delete the key idea?"
        leftTitle="no"
        rightTitle="yes"
        onDiscard={() => {
          const nwItems = [...keyIdeas];
          nwItems.splice(removeId.current || 0, 1);
          setKeyIdeas(nwItems);
          setRemoveKeyShow(false);
        }}
        onOk={() => {
          setRemoveKeyShow(false);
        }}
      />
    </MainLayout>
  );
};
export default EditUnit;
