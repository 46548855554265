import { atom } from "recoil";
import { Activity, findRequest, Lesson, Member, MemberCodeDto } from "../models";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();
export const checkUser = atom<boolean>({
  key: "checkUser_key",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
export const UserInfo = atom<Member | undefined>({
  key: "UserInfo_key",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const Email = atom<MemberCodeDto >({
  key: "Email_key",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const curActivity = atom<Activity | undefined>({
  key: "cur_activity_key",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const userToken = atom<string | undefined>({
  key: "user_token_key",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const signupData = atom<string | undefined>({
  key: "signup_data_key",
  default: undefined,
});
export const findData = atom<string>({
  key: "find_data_key",
  default: "",
});
export const findClass = atom<string>({
  key: "find_class_key",
  default: "",
});
export const findCata = atom<findRequest>({
  key: "find_cata",
  default: {
    languageFocus: "",
    lessonFocus: "",
    skillFocus: "",
    activity_Type: "",
    classId: "",
  },
  effects_UNSTABLE: [persistAtom],
});
export const findSubCata = atom<string[]>({
  key: "find_sub_cata",
  default: [],
});
export const CurLesson = atom<Lesson>({
  key: "current_lesson_key",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const curClass = atom<string>({
  key: "cur_class_key",
  default: "",
});
export const securityKey = atom<string | undefined>({
  key: "security_Key",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const dashboardActivityPage = atom<number>({
  key: "activity_page_key",
  default: 1,
});
export const dashboardLessonPage = atom<number>({
  key: "lesson_page_key",
  default: 1,
});
export const dashboardActivityFirst = atom<boolean>({
  key: "activity_first_key",
  default: true,
});
export const dashboardLessonFirst = atom<boolean>({
  key: "lesson_first",
  default: true,
});
export const addClassShow = atom<boolean>({
  key: "add_class_show",
  default: false,
});
export const addFolderShow = atom<boolean>({
  key: "add_show_show",
  default: false,
});
export const selectActivityId = atom<string>({
  key: "select_add_activity_id",
  default: "",
});
export const selectLessonId = atom<string>({
  key: "select_add_lesson_id",
  default: "",
});

export const selectedActivityInLesson = atom<Activity | undefined>({
  key: "selected_Activity_in_lesson_key",
  default: undefined,
});
export const showUnverified = atom<boolean>({
  key: "show_unverified_modal_key",
  default: false,
});
export const confirmAdaptShow = atom<boolean>({
  key: "show_confirm_adapt_modal_key",
  default: false,
});
export const orgActivityInAdapt = atom<Activity | undefined>({
  key: "org_Activity_in_Adapt_key",
  default: undefined,
});
export const curFolder = atom<string>({
  key: "cur_folder_key",
  default: "",
});
export const curItem = atom<string>({
  key: "current_item_key",
  default: "",
});
export const adaptId = atom<string | undefined>({
  key: "adapt_activity_id",
  default: undefined,
});

export const orderLeftSideBar = atom<boolean>({
  key: "order_drag_drop",
  default: false,
});
