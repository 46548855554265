import axios, { AxiosError, AxiosRequestConfig } from "axios";
import {
  ActivitiesPage,
  Activity,
  ActivityMappingDto,
  AdaptInfo,
  AttachData,
  Classes,
  CollectionItem,
  CountrysResponse,
  CurrentLocation,
  Folder,
  Lesson,
  LessonInfoDto,
  LessonSection,
  LessonsPage,
  LoginRes,
  Member,
  MemberCodeDto,
  ProfileDatas,
  Prompt,
  UploadItems,
} from "../models";
import {
  CollectionMembers,
  Course,
  CourseMembers,
  Units,
} from "../models/Course";
import { setRecoil } from "recoil-nexus";
import TokenService from "./TokenService";
import { UserInfo } from "../store";
import { FolderItem, FolderItemDto } from "../models/FolderItem";
import { Interface } from "readline";
const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
const apiClient = axios.create({
  baseURL: `${backend_baseUrl}/api`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accepts: "application/json",
  },
  withCredentials: false,
});
const uploadClient = axios.create({
  baseURL: `${backend_baseUrl}/api`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Methods": "GET,PUT,POST",
  },
  withCredentials: false,
});
/***
 * Http Client Object
 */
const defaultHeader: AxiosRequestConfig<any> = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    Accepts: "application/json",
  },
  withCredentials: false,
};
const uploadHeader: AxiosRequestConfig<any> = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Methods": "GET,PUT,POST",
  },
  withCredentials: false,
};
apiClient.interceptors.request.use(async (config) => {
  const token = TokenService.getLocalAccessToken();
  let sToken = "";
  try {
    if (token) sToken = JSON.parse(token);
  } catch (er) {
    sToken = token ?? "";
  }
  if (config && config.headers && sToken) {
    // config.headers = { ...config.headers };
    config.headers["Authorization"] = `Bearer ${sToken}`;
  }
  if (config.headers) config.headers["Content-Type"] = "application/json";
  return config;
});
uploadClient.interceptors.request.use(async (config) => {
  const token = TokenService.getLocalAccessToken();
  let sToken = "";
  try {
    if (token) sToken = JSON.parse(token);
  } catch (er) {
    sToken = token ?? "";
  }
  if (config && config.headers && sToken) {
    // config.headers = { ...config.headers };
    config.headers["Authorization"] = `Bearer ${sToken}`;
  }
  if (config.headers) config.headers["Content-Type"] = "multipart/form-data";
  return config;
});
apiClient.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err: AxiosError) => {
    if (err.response?.status === 401) {
      TokenService.updateLocalAccessToken("");
      setRecoil(UserInfo, undefined);
    }
    return Promise.reject(err);
  }
);
uploadClient.interceptors.response.use(
  async (resp) => resp,
  (err: AxiosError) => {
    if (err.response?.status === 401) {
      TokenService.updateLocalAccessToken("");
      setRecoil(UserInfo, undefined);
    }
    return Promise.reject(err);
  }
);
const sendContact = async (formData: string) => {
  const response = await apiClient.post("Contact", formData, defaultHeader);
  return response;
};
const sendFeedback = async (formData: FormData) => {
  const response = await axios.post<any>(
    `${backend_baseUrl}/api/Contact/feedback`,
    formData,
    uploadHeader
  );
  return response.data;
};
const sendSupportMail = async (formData: string) => {
  const response = await apiClient.post(
    "Contact/Support",
    formData,
    defaultHeader
  );
  return response;
};
const signup = async (formData: string) => {
  const response = await apiClient.post(
    "Login/register",
    formData,
    defaultHeader
  );
  return response;
};
const login = async (formData: string) => {
  const response = await apiClient.post<Member>(
    "Login/login",
    formData,
    defaultHeader
  );
  return response.data;
};
const verifyUser = async (token: string) => {
  const response = await apiClient.get<Member>(
    `Login/confirm/${token}`,
    defaultHeader
  );
  return response;
};
const updateUserData = async (formData: string) => {
  const response = await axios.post<LoginRes>(
    `${backend_baseUrl}/api/Login/UpdateInfo`,
    formData,
    defaultHeader
  );
  return response.data;
};

const updateProfileData = async (memberId: string, formData: string) => {
  const response = await apiClient.post<Member>(
    `Login/profileInfo/${memberId}`,
    formData,
    defaultHeader
  );
  return response.data;
};
const syncUserInfo = async (memberId: string) => {
  const response = await apiClient.get<Member>(
    `Login/sync/${memberId}`,
    defaultHeader
  );
  return response.data;
};
const getClassInfo = async (clsId?: string) => {
  const response = await apiClient.get<Classes>(
    `Folder/GetClassInfo/${clsId}`,
    defaultHeader
  );
  return response.data;
};
const updateClassInfo = async (uid: string, formData: string) => {
  const response = await apiClient.post<Classes>(
    `Folder/UpdateClass/${uid}`,
    formData,
    defaultHeader
  );
  return response.data;
};
const getUserInfo = async (uid: string) => {
  const response = await apiClient.get<Member>(
    `Login/GetMemberInfo/${uid}`,
    defaultHeader
  );
  return response.data;
};
const getFolderInfo = async (folderId?: string) => {
  const response = await apiClient.get<Folder>(
    `Folder/GetFolderInfo/${folderId}`,
    defaultHeader
  );
  return response.data;
};
const updateFolderInfo = async (uid: string, formData: string) => {
  const response = await apiClient.post<Folder>(
    `Folder/UpdateFolder/${uid}`,
    formData,
    defaultHeader
  );
  return response.data;
};
const deleteFolder = async (folderId?: string) => {
  const response = await apiClient.get(
    `Folder/DeleteFolder/${folderId}`,
    defaultHeader
  );
  return response.data;
};
const deleteClass = async (clsId?: string) => {
  const response = await apiClient.get(
    `Folder/DeleteClass/${clsId}`,
    defaultHeader
  );
  return response.data;
};
const saveActivity = async (formData: string) => {
  const response = await apiClient.post<Activity>(
    `${backend_baseUrl}/api/Activity/save_activity_data`,
    formData,
    defaultHeader
  );
  return response.data;
};

const saveFileActivity = async (formData: string) => {
  const response = await apiClient.post(
    `${backend_baseUrl}/api/Activity/create_file`,
    formData,
    defaultHeader
  );
  return response.data;
};

const getActivities = async (memberId: string, page: number) => {
  const response = await apiClient.get<ActivitiesPage>(
    `Activity/MyActivity/${memberId}/${page}`,
    defaultHeader
  );
  return response.data;
};
const allActivities = async () => {
  const response = await apiClient.get<Activity[]>(
    `Activity/AllActivity`,
    defaultHeader
  );
  return response.data;
};
const getFolderActivities = async (folderId: string) => {
  const response = await apiClient.get<Activity[]>(
    `Folder/GetActivities/${folderId}`,
    defaultHeader
  );
  return response.data;
};
const getClassActivities = async (classId: string) => {
  const response = await apiClient.get<Activity[]>(
    `Folder/GetActivitiesByClass/${classId}`,
    defaultHeader
  );
  return response.data;
};
const recentActivities = async (memberId: string) => {
  const response = await apiClient.get<Activity[]>(
    `Activity/RecentActivity/${memberId}`,
    defaultHeader
  );
  return response.data;
};
// const adaptActivity = async (
//   memberId: string,
//   orgActivityId: string,
//   classId: string
// ) => {
//   const response = await apiClient.get<Activity>(
//     `Activity/Adapt/${memberId}/${orgActivityId}/${classId}`,
//     defaultHeader
//   );
//   return response.data;
// };
const publishActivity = async (activityId: string) => {
  const response = await apiClient.get<Activity>(
    `Activity/Publish/${activityId}`,
    defaultHeader
  );
  return response.data;
};
const draftActivity = async (activityId: string) => {
  const response = await apiClient.get<Activity>(
    `Activity/Draft/${activityId}`,
    defaultHeader
  );
  return response.data;
};
const updateUserImageData = async (formData: FormData) => {
  const response = await axios.post<any>(
    `${backend_baseUrl}/api/Login/UpdateUserImage`,
    formData,
    uploadHeader
  );
  return response.data;
};
const getCodeMail = async (email: string) => {
  const response = await axios.post<string>(
    `${backend_baseUrl}/api/auth/send_code`,
    email,
    defaultHeader
  );
  return response.data;
};
const sendCodeAdmin = async (email: string) => {
  const response = await axios.post<string>(
    `${backend_baseUrl}/api/auth/send_code_admin`,
    email,
    defaultHeader
  );
  return response.data;
};
export const checkCodeEmail = async (memberCodeDto: MemberCodeDto) => {
  const response = await axios.get(
    `${backend_baseUrl}/api/auth/check_send_code`, {
      params: memberCodeDto
    }
  );
  return response.data;
};
const checkFirstUser = async (memberId: string) => {
  const response = await apiClient.get<Member>(
    `Login/SetMemberUse/${memberId}`,
    defaultHeader
  );
  return response.data;
};
const uploadAttachFiles = async (formData: FormData) => {
  const response = await uploadClient.post<UploadItems>(
    `${backend_baseUrl}/api/Activity/uploadFiles`,
    formData,
    uploadHeader
  );
  return response.data;
};
const createBlankDoc = async (memberId: string | undefined) => {
  if (memberId === undefined) return;
  const response = await apiClient.get<string>(
    `Activity/CreateDoc/${memberId}`,
    defaultHeader
  );
  return response.data;
};
const findActivities = async (memberId: string, params: string) => {
  const response = await apiClient.post<Activity[]>(
    `Activity/Find/${memberId}`,
    params,
    defaultHeader
  );
  return response.data;
};
const deleteDocument = async (documentId: string) => {
  const response = await apiClient.delete(
    `Activity/document/${documentId}`,
    defaultHeader
  );
  return response.data;
};
const deleteActivity = async (activityId: string) => {
  const response = await apiClient.delete(
    `Activity/Activity/${activityId}`,
    defaultHeader
  );
  return response.data;
};

const saveLesson = async (formData: string) => {
  const response = await apiClient.post<Lesson>(
    `Lesson/Save`,
    formData,
    defaultHeader
  );
  return response.data;
};
const lessonIntoFolder = async (folderId: string, lessonId: string) => {
  const response = await apiClient.get(
    `Lesson/AddFolder/${folderId}/${lessonId}`,
    defaultHeader
  );
  return response.data;
};
const getClassLessons = async (classId: string) => {
  const response = await apiClient.get<Lesson[]>(
    `Lesson/ClassLessons/${classId}`,
    defaultHeader
  );
  return response.data;
};
const getMyLessons = async (memberId: string, page: number) => {
  const response = await apiClient.get<LessonsPage>(
    `Lesson/MyLessons/${memberId}/${page}`,
    defaultHeader
  );
  return response.data;
};
const getAllLessons = async (memberId: string) => {
  const response = await apiClient.get<Lesson[]>(
    `Lesson/AllLessons/${memberId}`,
    defaultHeader
  );
  return response.data;
};
const getMyDatas = async (memberId: string) => {
  const response = await apiClient.get<ProfileDatas>(
    `Login/profileInfo/${memberId}`,
    defaultHeader
  );
  return response.data;
};
const getActivity = async (activityId: string) => {
  const response = await apiClient.get<Activity>(
    `Activity/Get/${activityId}`,
    defaultHeader
  );
  return response.data;
};
const googleSignup = async (formData: string) => {
  const response = await apiClient.post(
    "Login/signup",
    formData,
    defaultHeader
  );
  return response.data;
};
const googleSignin = async (formData: string) => {
  const response = await apiClient.post<LoginRes>(
    "Login/signin",
    formData,
    defaultHeader
  );
  return response.data;
};
const checkSecurityKey = async (key: string) => {
  const response = await apiClient.get(`Login/checkkey/${key}`, defaultHeader);
  return response;
};
const toggleBookmarked = async (activityId: string, memberId: string) => {
  const response = await apiClient.get<Activity>(
    `Activity/Bookmarked/${activityId}/${memberId}`,
    defaultHeader
  );
  return response.data;
};
const getCountries = async () => {
  const response = await axios.get<CountrysResponse>(
    "https://countriesnow.space/api/v0.1/countries/states"
  );
  return response.data;
};
const getLocationInfo = async () => {
  const response = await axios.get<CurrentLocation>("https://ipapi.co/json");
  return response.data;
};
const getAllFolders = async (memberId: string) => {
  const response = await apiClient.get<Folder[]>(
    `Activity/AllFolder/${memberId}`,
    defaultHeader
  );
  return response.data;
};
const getAllClasses = async (memberId: string) => {
  const response = await apiClient.get<Classes[]>(
    `Activity/AllClasses/${memberId}`,
    defaultHeader
  );
  return response.data;
};
const addToClassAcitivity = async (classId: string, activityId: string) => {
  const response = await apiClient.get(
    `Activity/AddtoClass/${classId}/${activityId}`,
    defaultHeader
  );
  return response.data;
};
const addToClassLesson = async (classId: string, lessonId: string) => {
  const response = await apiClient.get(
    `Lesson/AddtoClass/${classId}/${lessonId}`,
    defaultHeader
  );
  return response.data;
};
const addToFolderLesson = async (folderId: string, lessonId: string) => {
  const response = await apiClient.get(
    `Lesson/AddtoFolder/${folderId}/${lessonId}`,
    defaultHeader
  );
  return response.data;
};
const addToFolderActivity = async (folderId: string, activityId: string) => {
  const response = await apiClient.get(
    `Activity/AddtoFolder/${folderId}/${activityId}`,
    defaultHeader
  );
  return response.data;
};
const getAdapts = async (activityId: string) => {
  const response = await apiClient.get<AdaptInfo>(
    `Activity/GetAdapts/${activityId}`,
    defaultHeader
  );
  return response.data;
};
const updatedAttachedFiles = async (activityId: string, formData: string) => {
  const response = await apiClient.post<any>(
    `Activity/updated_attached_files/${activityId}`,
    formData,
    defaultHeader
  );
  return response.data;
};
const getAdaptations = async (activityId: string, page: number) => {
  const response = await apiClient.get<ActivitiesPage>(
    `Activity/getAdaptations/${activityId}/${page}`,
    defaultHeader
  );
  return response.data;
};
const logout = async (memberId: string) => {
  const response = await apiClient.get(
    `Login/logout/${memberId}`,
    defaultHeader
  );
  return response.data;
};
const getCourseInfo = async (uid: string) => {
  const response = await apiClient.get<Course>(
    `Unit/get_course_info/${uid}`,
    defaultHeader
  );
  return response.data;
};

const updateImageCourseInfo = async (body: string) => {
  const response = await apiClient.put(
    `Unit/update_default_image`,
    body,
    defaultHeader
  );
  return response.data;
};

const getCollectionInfo = async (uid: string) => {
  const response = await apiClient.get<FolderItemDto>(
    `FolderItem/get_collection_info/${uid}`,
    defaultHeader
  );
  return response.data;
};

const saveCourseInfo = async (formData: string) => {
  const response = await apiClient.post<any>(
    `Unit/save_course_info`,
    formData,
    defaultHeader
  );
  return response.data;
};

const saveCollectionInfo = async (formData: string) => {
  const response = await apiClient.post<any>(
    `FolderItem/save_collection_info`,
    formData,
    defaultHeader
  );
  return response.data;
};

const saveUnitInfo = async (formData: string) => {
  const response = await apiClient.post<any>(
    `Unit/save_unit_data`,
    formData,
    defaultHeader
  );
  return response.data;
};

const getUnitInfo = async (uid: string) => {
  const response = await apiClient.get<Units>(
    `Unit/get_unit_info/${uid}`,
    defaultHeader
  );
  return response.data;
};
const getUnitsByCourse = async (courseId: string) => {
  const response = await apiClient.get<Units[]>(
    `Unit/get_units/${courseId}`,
    defaultHeader
  );
  return response.data;
};
const getChilrenByCollection = async (folderId?: string) => {
  const response = await apiClient.get<CollectionItem>(
    `FolderItem/get_chilren_by_collection/${folderId}`,
    defaultHeader
  );
  return response.data;
};
const getDataDashboard = async () => {
  const response = await apiClient.get(
    `Unit/get_my_collection_course`,
    defaultHeader
  );
  return response.data;
};

const getLessonsByUnit = async (uuid: string) => {
  const response = await apiClient.get<LessonInfoDto[]>(
    `Unit/get_lessons_by_unit/${uuid}`,
    defaultHeader
  );
  return response.data;
};

const getLessonsByUnitPublish = async (uuid: string, lessonId: string) => {
  const response = await apiClient.get<LessonInfoDto[]>(
    `Unit/get_lessons_by_unit_publish/${uuid}/${lessonId}`,
    defaultHeader
  );
  return response.data;
};

const getActivityMappingLesson = async (uuid: string) => {
  const response = await apiClient.get<ActivityMappingDto>(
    `Activity/get_activity_mapping/${uuid}`,
    defaultHeader
  );
  return response.data;
};

const getMyCourses = async () => {
  const response = await apiClient.get<Course[]>(
    `Unit/get_my_courses`,
    defaultHeader
  );
  return response.data;
};

const getMyCollection = async () => {
  const response = await apiClient.get<FolderItem[]>(
    `FolderItem/get_my_collection`,
    defaultHeader
  );
  return response.data;
};

const getLessonInfo = async (uuid: string) => {
  const response = await apiClient.get<any>(
    `Lesson/get_lesson_data/${uuid}`,
    defaultHeader
  );
  return response.data;
};

const updateOrderLessonInfo = async (lessonIds: string[]) => {
  const response = await apiClient.put<any>(
    `Lesson/update_order_lesson`,
    lessonIds,
    defaultHeader
  );
  return response.data;
};

const updateOrderUnitInfo = async (lessonIds: string[]) => {
  const response = await apiClient.put<any>(
    `Unit/update_order_unit`,
    lessonIds,
    defaultHeader
  );
  return response.data;
};

const getLessonSectionById = async (uuid: string) => {
  const response = await apiClient.get<LessonSection>(
    `Lesson/get_lesson_section_by_id/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const deleteLesson = async (uuid: string) => {
  const response = await apiClient.delete(
    `Lesson/delete_lesson/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const deleteUnit = async (uuid: string) => {
  const response = await apiClient.delete(
    `Unit/delete_unit/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const deleteActivityCollection = async (uuid: string) => {
  const response = await apiClient.delete(
    `FolderItem/Activity/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const deleteCourse = async (uuid: string) => {
  const response = await apiClient.delete(
    `Unit/delete_course/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const deleteCollection = async (uuid: string) => {
  const response = await apiClient.delete(
    `FolderItem/delete_collection/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const getFollowUsers = async () => {
  const response = await apiClient.get<Member[]>(
    `Asset/get_follow_user_list`,
    defaultHeader
  );
  return response.data;
};
const getCourseMemberUsers = async (uuid: string) => {
  const response = await apiClient.get<CourseMembers[]>(
    `Unit/get_course_members/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const getCollectionMemberUsers = async (uuid: string) => {
  const response = await apiClient.get<CollectionMembers[]>(
    `FolderItem/get_collection_members/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const regCourseMember = async (formData: string) => {
  const response = await apiClient.post(
    `Unit/reg_member_info`,
    formData,
    defaultHeader
  );
  return response.data;
};
const regCollectionMember = async (formData: string) => {
  const response = await apiClient.post(
    `FolderItem/reg_member_info`,
    formData,
    defaultHeader
  );
  return response.data;
};
const updateSectionInfo = async (formData: string) => {
  const response = await apiClient.post(
    `Lesson/update_section_info`,
    formData,
    defaultHeader
  );
  return response.data;
};
const deleteMemberRole = async (uuid: string) => {
  const response = await apiClient.delete(
    `Unit/delete_member_role/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const deleteCollectionMemberRole = async (uuid: string) => {
  const response = await apiClient.delete(
    `FolderItem/delete_member_role/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const getCourseLevel = async (uuid: string) => {
  const response = await apiClient.get<number>(
    `Unit/get_course_level/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const readActivity = (uuid: string) => {
  apiClient.get<number>(`Lesson/read_activity/${uuid}`, defaultHeader);
};
const toggleShowActivity = async (uuid: string) => {
  const response = await apiClient.get<Activity>(
    `Activity/toggle_shown/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const adaptActivity = async (uuid: string) => {
  const response = await apiClient.get<Activity>(
    `Activity/adapt_activity/${uuid}`,
    defaultHeader
  );
  return response.data;
};
const assignActivityForLesson = async (formData: string) => {
  const response = await apiClient.post<any>(
    `Activity/assign_activity_data`,
    formData
  );
  return response.data;
};
const queryActivityContent = async (formData: string) => {
  const response = await apiClient.post<any>(
    `Assist/generate_activity_message`,
    formData
  );
  return response.data;
};
const getPromptContent = async () => {
  const response = await apiClient.get<Prompt>(`Asset/get_prompt_content`);
  return response.data;
};

const HttpService = {
  sendContact,
  sendSupportMail,
  signup,
  verifyUser,
  updateUserData,
  login,
  updateProfileData,
  getClassInfo,
  updateClassInfo,
  getUserInfo,
  getFolderInfo,
  updateFolderInfo,
  deleteFolder,
  deleteClass,
  saveActivity,
  getActivities,
  recentActivities,
  adaptActivity,
  assignActivityForLesson,
  publishActivity,
  draftActivity,
  updateUserImageData,
  checkFirstUser,
  uploadAttachFiles,
  createBlankDoc,
  getFolderActivities,
  findActivities,
  deleteDocument,
  deleteActivity,
  saveLesson,
  getClassLessons,
  getMyLessons,
  getActivity,
  googleSignup,
  googleSignin,
  deleteLesson,
  getMyDatas,
  getCountries,
  getLocationInfo,
  checkSecurityKey,
  toggleBookmarked,
  allActivities,
  getAllLessons,
  getAllFolders,
  getAllClasses,
  addToClassAcitivity,
  addToClassLesson,
  addToFolderLesson,
  addToFolderActivity,
  getAdapts,
  getAdaptations,
  getClassActivities,
  logout,
  sendFeedback,
  syncUserInfo,
  lessonIntoFolder,
  getCourseInfo,
  getCollectionInfo,
  getUnitInfo,
  saveCourseInfo,
  saveCollectionInfo,
  getLessonsByUnitPublish,
  getActivityMappingLesson,
  getMyCourses,
  saveUnitInfo,
  getUnitsByCourse,
  getChilrenByCollection,
  getLessonsByUnit,
  getDataDashboard,
  getLessonInfo,
  getLessonSectionById,
  updateOrderLessonInfo,
  updateOrderUnitInfo,
  deleteUnit,
  deleteActivityCollection,
  deleteCourse,
  deleteCollection,
  getFollowUsers,
  regCourseMember,
  regCollectionMember,
  getCourseMemberUsers,
  getCollectionMemberUsers,
  deleteMemberRole,
  deleteCollectionMemberRole,
  getCourseLevel,
  readActivity,
  updatedAttachedFiles,
  toggleShowActivity,
  updateSectionInfo,
  queryActivityContent,
  getPromptContent,
  getMyCollection,
  updateImageCourseInfo,
  getCodeMail,
  sendCodeAdmin,
  checkCodeEmail,
  saveFileActivity
};
export default HttpService;
