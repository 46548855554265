import { useMemo } from "react";
import remove_icon from "../assets/images/icons/red_minus.svg";
import down_icon from "../assets/images/icons/down_arrow.svg";
import up_icon from "../assets/images/icons/up_arrow.svg";
import SunEditor from "suneditor-react";
import plugins from "suneditor/src/plugins";
interface Props {
  order: number;
  nIndex: number;
  removable: boolean;
  count: number;
  nValue: string;
  onRemove: (index: number) => void;
  onMoveUp?: (index: number) => void;
  onMoveDown?: (index: number) => void;
  onChange: (val: string, index: number) => void;
}
const InstructEdit = (props: Props) => {
  const {
    order,
    nIndex,
    removable,
    nValue,
    onRemove,
    onChange,
    count,
    onMoveUp,
    onMoveDown,
  } = props;
  const isUp = nIndex > 0;
  const isDown = nIndex < count - 1;
  return (
    <div className="d-flex my-2">
      <div className="order-cell">
        <div>
          {isUp && (
            <div>
              <img
                src={up_icon}
                alt=""
                className="cursor"
                onClick={() => onMoveUp && onMoveUp(nIndex)}
              />
            </div>
          )}
          <b className="d-block">{order}.</b>
          {isDown && (
            <div>
              <img
                src={down_icon}
                alt=""
                className="cursor"
                onClick={() => onMoveDown && onMoveDown(nIndex)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex-grow-1 mx-1">
        <div className="editor_box">
          <SunEditor
            defaultValue={nValue}
            setOptions={{
              plugins: plugins,
              height: "100%",
              buttonList: [
                ["undo", "redo"],
                ["formatBlock"],
                ["blockquote"],
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                ],
                ["table", "list", "link", "image", "video"],
              ],
            }}
            onChange={(content: string) => {
              console.info("content:", nIndex, content);
              if (nValue != content) onChange(content, nIndex);
            }}
          />
          {/* <CKEditor
            initData={nValue}
            config={{
              extraPlugins: 'ckeditor_wiris',
              removePlugins: 'elementspath',
              allowedContent: true,
              removeButtons: ['Maximize'],
              toolbar: editorToolbar,
              contentsCss: [
                "body {font-size: 16px; font-family :'Roboto Slab' }",
              ],
            }}
            onChange={(e) => {
              onChange(e.editor.getData(), nIndex);
            }}
            onBeforeLoad={(CKEDITOR) => {
              CKEDITOR.plugins.addExternal(
                'ckeditor_wiris',
                '/mathtype-ckeditor4/',
                'plugin.js'
              );
            }}
          /> */}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <img
          src={remove_icon}
          className={removable ? "visible" : "invisible"}
          alt=""
          onClick={() => {
            if (removable) onRemove(nIndex);
          }}
        />
      </div>
    </div>
  );
};
export default InstructEdit;
