import { Container, Form, Modal } from 'react-bootstrap';
import close from '../../assets/images/icons/red_close.svg';
interface Props {
  show: boolean;
  onOk: () => void;
}
export const CreateNewGroupModal = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className='dashboard_video'
      centered={true}
    >
      <Modal.Header>
        <Modal.Title>
          Create new group
          <img
            src={close}
            alt=''
            onClick={() => {
              props.onOk();
            }}
            className='closeBtn'
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex justify-content-center'>
        <Container>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Group name</Form.Label>
            <Form.Control type='text' placeholder='Enter email' />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Add Teachers by Name or Email</Form.Label>
            <Form.Control type='text' placeholder='Enter email' />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Share invite link</Form.Label>
            <Form.Text className='text-muted'>
              https://www.figma.com/file/Q3mDGdZYuVj3EHDz3ELrxK/Edusfere-Alfa
            </Form.Text>
          </Form.Group>
          <div className='d-flex justify-content-end'>
            <button className='no-button border-0 bg-body'>Copy link to clipboard</button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
