import { useRecoilState, useRecoilValue } from "recoil";
import { UserInfo, curItem, orderLeftSideBar } from "../store";
import plus from "../assets/images/images/group_81.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMyCollection, useMyCourse } from "../services/Query";
import ReactLoading from "react-loading";
import { Course } from "../models/Course";
import { useEffect, useMemo, useState } from "react";
import { FolderItem } from "../models/FolderItem";

export const LeftFolder = ({ currentId, setCurrentId}: any) => {
  const { id } = useParams<{id: string}>();
  const { data: myCourse, isLoading: isCourseLoading, refetch: reloadMyCourse } = useMyCourse();
  const { data: myCollection, isLoading: isCollectionLoading, refetch: reloadMyCollection } = useMyCollection();
  const [curId, setCurId] = useRecoilState(curItem);
  const [folderId, setFolderId] = useState<string>(id || "");
  const userInfo = useRecoilValue(UserInfo);
  const orderInfo = useRecoilValue(orderLeftSideBar)
  const navigate = useNavigate();


  const parentFolder = useMemo(() => {
      return myCollection?.filter(t => t.parentId == null)
  }, [myCollection, currentId])

  useEffect(() => {
    setTimeout(() => {
      if (!!orderInfo) {
        reloadMyCourse();
        reloadMyCollection();
      }
    }, 500)
  }, [orderInfo])

  useEffect(() => {
    var type = window.location.pathname;
    if (!!currentId) {
      if (type.includes("course") || type.includes("unit")) {
        setCurId(currentId);
      }
      if (type.includes("collection")) {
        setFolderId(currentId);
      }
    }
  }, [currentId, window.location.pathname, orderInfo])

  const isSelected = (item: Course, curId: string) => {
    return (
      item.course_Id == curId || (!!currentId && item.course_Id == currentId) ||
      item._Units.filter((x) => x.id == curId || x.id == currentId).length > 0
    );
  };

  const isSelectedCollection = (item: FolderItem, curId: string) => {
    return item.id == curId || item.parentId == curId || (!!currentId && ((item.id == currentId) || item.parentId == currentId)) || item.childItems?.filter((record: any) => isSelectedCollection(record, curId))?.length > 0  
  };

  const renderMenuFolder = (folder: FolderItem) => {
    if (folder?.parentId != null) {
      return (
        <ul>
          <li key={folder.id} className="mb-0">
            <Link
              to={`/dashboard/sub_collection/${folder.id}`}
              onClick={() => {
                setFolderId(folder.id);
                !!curId && setCurId("")
                !!currentId && setCurrentId("")
              }} 
              className={(folderId == folder.id || (!currentId && currentId == folder.id)) ? "selectedItem" : ""}
            >
              {folder.name}
            </Link>
          </li>
          {!!folder.childItems && folder.childItems?.map((item: FolderItem) => isSelectedCollection(item, folderId) && renderMenuFolder(item))}
        </ul>
      )
    }
  } 

  return (
    <>
      <ul className="ps-0">
        <h5 className=" text-uppercase">
          Courses
          {userInfo?._Level === 0 && (
            <img
              src={plus}
              alt=""
              className="cursor"
              width={43}
              onClick={() => {
                navigate("/edit_course/-");
              }}
            />
          )}
        </h5>
        {isCourseLoading && (
          <div>
            <ReactLoading
              type="spinningBubbles"
              className="mx-auto my-3"
              height={"80px"}
              width={"80px"}
              color="#666666"
            />
          </div>
        )}
        {myCourse?.map((it, idx) => (
          <li key={it.course_Id}>
            <Link
              to={`/dashboard/course/${it.course_Id}`}
              onClick={() => {
                setCurId(it.course_Id);
                !!folderId && setFolderId("")
                !!currentId && setCurrentId("")
              }}
              className={curId === it.course_Id ? "selectedItem" : ""}
            >
              {it.course_Name}
            </Link>
            <ul className="ms-0 ps-0 pt-0">
              {isSelected(it, curId) &&
                it._Units &&
                it._Units
                  .filter((x) => x.isDeleted != true)
                  .sort((a, b) => a.order - b.order)
                  .map((_unit) => (
                    <li key={_unit.id} className="mb-0">
                      <Link
                        to={`/dashboard/unit/${_unit.id}`}
                        onClick={() => {
                          setCurId(_unit.id);
                          !!currentId && setCurrentId("")
                        }}
                        className={curId == _unit.id ? "selectedItem" : ""}
                      >
                        {_unit.name}
                      </Link>
                    </li>
                  ))}
            </ul>
          </li>
        ))}
      </ul>
      <ul className="ps-0 collection-list">
        <h5 className="text-uppercase">
          Collections
          {userInfo?._Level === 0 && (
            <img
              src={plus}
              alt=""
              className="cursor"
              width={43}
              onClick={() => {
                navigate(`/edit_folder/-?type=collection`);
                !!currentId && setCurrentId("")
              }}
            />
          )}
        </h5>
        {isCollectionLoading && (
          <div>
            <ReactLoading
              type="spinningBubbles"
              className="mx-auto my-3"
              height={"80px"}
              width={"80px"}
              color="#666666"
            />
          </div>
        )}
        {!!parentFolder && parentFolder?.map((it, idx) => (
          <li key={it.id}>
            <Link
              to={`/dashboard/collection/${it.id}`}
              onClick={() => {
                setFolderId(it.id);
                !!curId && setCurId("")
                !!currentId && setCurrentId("")
              }}
              className={folderId === it.id ? "selectedItem" : ""}
            >
              {it.name}
            </Link>
              {
                isSelectedCollection(it, folderId) && it.childItems?.length > 0 && it.childItems?.map((record: any) => {
                  return renderMenuFolder(record)
                })
              }
          </li>
        ))}
      </ul>
    </>
  );
};
