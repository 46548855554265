import { ReactNode, useState } from "react";
import { MainLayout } from "../components/Layouts/MainLayout";
import drop_down from "../assets/images/icons/drop_down.svg";
import check_icon from "../assets/images/icons/check_icon.svg";
import unbookmarked from "../assets/images/icons/bookmark-unfilled.png";
import bookmarked from "../assets/images/icons/bookmark-filled.png";
import adapt from "../assets/images/images/adapt.png";
import build from "../assets/images/images/build.png";
import dots from "../assets/images/icons/three_dot.svg";
import print from "../assets/images/icons/black_print.svg";
import man_one from "../assets/images/icons/single_man.svg";
import man_tw from "../assets/images/icons/double_man.svg";
import man_th from "../assets/images/icons/thrd_man.svg";
import recycle from "../assets/images/icons/recycle.svg";
import { useNavigate, useParams } from "react-router-dom";
import { SpecStr, timeArry } from "../models/defaults";
import { Form, Row } from "react-bootstrap";
import React from "react";

import { useGetActivity } from "../services/Query";
import HttpService from "../services/HttpServices";
import { confirmAdaptShow, orgActivityInAdapt, UserInfo } from "../store";
import { useRecoilState, useRecoilValue } from "recoil";
import { isMyBookmarked } from "../services/Utils";
/**
 *
 * Template View page : /build/template/view
 */
export const TemplateView = () => {
  const [isAdapt, setIsAdapt] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean[]>([false, false]);
  const [close, setclose] = useState(true);

  let { activityId } = useParams();
  const [confirmAdapt, setconfirmAdaptShow] = useRecoilState(confirmAdaptShow);
  const [orgActivity, setOrgActivity] = useRecoilState(orgActivityInAdapt);
  const { data: cActivity, refetch: getData } = useGetActivity(activityId);
  const userinfo = useRecoilValue(UserInfo);
  const renderIdeaKinds = () => {
    const elements: ReactNode[] = [];
    const types = cActivity?.activity_IdeaKinds;
    if (types) {
      types.forEach((it, idx) => {
        elements.push(
          <div
            className={`${idx % 2 === 0 ? "col-md-4" : "col-md-8"}`}
            key={idx}
          >
            <Form.Check
              type="checkbox"
              label={it}
              value={it}
              checked={true}
              disabled
            />
          </div>
        );
      });
    }

    return elements;
  };
  const getActivityType = (): string => {
    if (!cActivity) return "";
    switch (cActivity.activity_Type) {
      case "drill":
        return "drill";
      case "game":
        return "game";
      case "song/music":
        return "song";
      case "worksheet":
        return "worksheet";
      case "communicative task":
        return "communicative";
      case "presentation":
        return "presentation";
      case "project":
        return "project";
      case "prompt":
        return "prompt";
      default:
        break;
    }
    return "communicative";
  };
  return (
    <MainLayout>
      <div className="d-flex bg_selected py-2">
        <h4 className="text-center w-100">
          this is a preview of how your activity will appear
        </h4>
      </div>
      <div className="d-flex">
        <div className="left_menu">
          <h5 className="mt-4 text-center">TAGS</h5>
          <h5
            className="button"
            onClick={() => {
              const openStatus = [false, false];
              openStatus[0] = !isOpen[0];
              setIsOpen(openStatus);
            }}
          >
            IDEAL CLASS STATS <img src={drop_down} alt="" />
          </h5>
        </div>
        <div className="dash_content flex-fill">
          <div className="d-flex ps-xl-5 ms-3">
            <div
              className={`dashboard_box p-3 ms-1 d-flex flex-column ${getActivityType()}`}
            >
              <div className="flex-fill d-flex">
                <h3 className="flex-fill">{cActivity?.activity_Name}</h3>
                <img
                  src={
                    isMyBookmarked(userinfo?.member_Id, cActivity)
                      ? bookmarked
                      : unbookmarked
                  }
                  className="tip_icon cursor"
                  alt=""
                  onClick={async () => {
                    if (cActivity && userinfo) {
                      try {
                        await HttpService.toggleBookmarked(
                          cActivity.activity_Id,
                          userinfo.member_Id
                        );
                        getData();
                      } catch (ex) {}
                    }
                  }}
                  height={61}
                />
              </div>
              <div className="row details">
                <div className="col-md-6 d-flex align-self-end">
                  <div>
                    <b>{cActivity?.activity_PrepTime}</b>
                    <span className="ms-1">prep</span>
                    <b className="ms-2">{cActivity?.activity_UseTime}</b>
                    <span className="ms-2">class time</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <b>submitted</b> by {cActivity?.creator?.member_FirstName}{" "}
                    {cActivity?.creator?.member_LastName}
                    <img src={check_icon} alt="" />
                  </div>
                  {isAdapt && (
                    <div>
                      adaptation by Chris Goss
                      <img src={check_icon} alt="" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex buttons flex-column align-self-center">
              <img
                src={adapt}
                width={32}
                className="cursor"
                alt=""
                onClick={() => {
                  if (cActivity) {
                    setOrgActivity(cActivity);
                    setconfirmAdaptShow(true);
                  }
                }}
              />
              <img src={build} width={30} alt="" />
              <img src={dots} width={10} alt="" className="ms-2" />
              <img src={print} width={30} alt="" />
            </div>
          </div>
          <div className="ms-3 mt-3 ps-xl-5 d-none">
            <img src={man_one} alt="" />
            <img src={man_tw} alt="" className="ms-2" />
            <img src={man_th} alt="" className="ms-2" />
            <img src={recycle} alt="" className="ms-2" />
          </div>

          <div className="ms-3 mt-3 ps-xl-5 template_wrap">
            <h5>Description</h5>
            <p>{cActivity?.activity_Desc}</p>

            {cActivity?.additionNote && (
              <div className="mt-4">
                <h5>additional notes</h5>
                <p>{cActivity?.additionNote}</p>
              </div>
            )}

            <h5>activity type</h5>
            <div className="types_options">
              <div className="d-flex flex-wrap">
                {cActivity?.activity_Type
                  .split(SpecStr.split_common)
                  .map((it, idx) => (
                    <div className={`mt-2 text-start pe-4`} key={idx}>
                      <span className={`type_cell on`}>{it}</span>
                    </div>
                  ))}
              </div>
            </div>

            <Row className="sg-row mx-0 mt-3">
              <div className="col-md-6 ps-0">
                <Form.Group className="mb-1">
                  <Form.Label className="h5">
                    about how much time will this activity take to prepare?
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    disabled
                    value={cActivity?.activity_PrepTime}
                  >
                    <option value=""></option>
                    {timeArry.map((it, idx) => (
                      <option value={it} key={idx}>
                        {it}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </Row>
            <Row className="sg-row mx-0 mt-3">
              <div className=" col-md-6 ps-0">
                <Form.Group className="mb-1">
                  <Form.Label className="h5">
                    about how much class time will this activity use?
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    disabled
                    value={cActivity?.activity_UseTime}
                  >
                    <option value=""></option>
                    {timeArry.map((it, idx) => (
                      <option value={it} key={idx}>
                        {it}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </Row>
            <Row className="sg-row  mx-0 mt-3">
              <div className=" col-md-10 ps-0">
                <Form.Group className="mb-1">
                  <Form.Label className="h5">
                    does your activity idea include...
                  </Form.Label>
                </Form.Group>
              </div>
            </Row>
            <Row className="sg-row mx-0">
              <React.Fragment>{renderIdeaKinds()}</React.Fragment>
            </Row>
            <Row className="sg-row mx-0 mt-3">
              <div className="col-md-12 ps-0">
                <Form.Group>
                  <Form.Label className="h5">
                    After initial use, can this activity be done again without
                    changing or replacing materials?
                    <b
                      className={`ms-3 fs-4 ${
                        cActivity?.activity_AfterChangable
                          ? "text_info"
                          : "text_warn"
                      }`}
                    >
                      {cActivity?.activity_AfterChangable ? "yes" : "no"}
                    </b>
                  </Form.Label>
                </Form.Group>
              </div>
            </Row>

            <Row className="sg-row mx-0 mt-3">
              <div className="col-md-6 ps-0">
                <Form.Group className="mb-1">
                  <Form.Label className="h5">skill focus </Form.Label>
                </Form.Group>
              </div>
            </Row>

            <Row className="sg-row  mx-0 mt-3">
              <div className="col-md-6 ps-0">
                <Form.Group className="mb-1">
                  <Form.Label className="h5">class size</Form.Label>
                </Form.Group>
              </div>
              <div className="px-md-4 col-md-6">
                <Form.Group className="mb-1">
                  <Form.Label className="h5">student age</Form.Label>
                </Form.Group>
              </div>
            </Row>

            <Row className="sg-row mx-0 mt-3">
              <div className="col-md-6 ps-0">
                <Form.Group className="mb-1">
                  <Form.Label className="h5">proficiency levels</Form.Label>
                </Form.Group>
              </div>
              <div className="px-md-4 col-md-6">
                <Form.Group className="mb-1">
                  <Form.Label className="h5">energy level</Form.Label>
                </Form.Group>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
