import { MainLayout } from "../components/Layouts/MainLayout";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { Form, Spinner } from "react-bootstrap";
import plugins from "suneditor/src/plugins";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  useAttachFiles,
  useDeleteCollection,
  useGetCollectionInfo,
  useSaveCollectionInfo,
} from "../services/Query";
import { useForm } from "react-hook-form";
import SunEditor from "suneditor-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { AttachData } from "../models";
import plus_icon from "../assets/images/icons/circle_plus_icon.svg";
import minus_icon from "../assets/images/icons/red_minus.svg";
import { ConfirmRemoveModal } from "../components/modals/ConfirmRemoveModal";
import InputFileEx from "../components/InputFileEx";
import { IFileItem } from "../components/modals/EditActivityModal";
import { ConfirmModal } from "../components/modals/ConfirmModal.server";
const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
type folderFrm = {
  id?: string | undefined;
  name: string;
  tags: string[];
  description: string | undefined;
  publisherIcon?: AttachData;
  parentId?: string | undefined
};
const EditSubCollection = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  let { folder_id, sub_folder_id } = useParams();
  const navigate = useNavigate();
  const { data: folderInfo, isLoading } = useGetCollectionInfo(sub_folder_id);
  const { mutate: saveCollection, isLoading: isSaving } = useSaveCollectionInfo();
  const { mutate: deleteItem, isLoading: isDeleting } = useDeleteCollection();
  const { isLoading: isUploading, mutate: onAttachFiles } = useAttachFiles();
  const [removeShow, setRemoveShow] = useState(false);
  const [pubLogFile, setPubLogFile] = useState<IFileItem>(null);
  const [keyTags, setKeyTags] = useState<string[]>(folderInfo?.tags ? folderInfo?.tags : [""]);
  const [removeKeyShow, setRemoveKeyShow] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const removeId = useRef<number | undefined>(undefined);
  const isAdded = folderInfo == undefined;
  const {
    register,
    setValue,
    watch,
    reset,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = useForm<folderFrm>({ mode: "onChange" });

  useEffect(() => {
    if (folderInfo) {
      reset({
        id: folderInfo.id,
        name: folderInfo.name,
        tags: folderInfo.tags,
        description: folderInfo.description || "",
      });
    } else {
      reset({
        id: undefined,
        name: "",
        tags: undefined,
        description: "",
      });
    }
    trigger();
  }, [folderInfo]);

  const saveCollectionInfo = (data: string) => {
    saveCollection(data, {
      onSuccess: (res) => {
        navigate(`/dashboard/sub_collection/${res.id}`);
      },
      onError: () => {},
    });
  };
  const onSave = () => {
    const values = getValues();
    const data = { ...values };
    data.tags = keyTags;
    data.parentId = folder_id;
    if (pubLogFile != null) {
      const formData = new FormData();
      formData.append("bgFile", pubLogFile);
      onAttachFiles(formData, {
        onSuccess: (files) => {
          if (files.bgItem) {
            data.publisherIcon = files.bgItem;
            saveCollectionInfo(JSON.stringify(data));
          }
        },
      });
    } else {
        saveCollectionInfo(JSON.stringify(data));
    }
  };
  const pub_icon = useMemo(() => {
    if (pubLogFile != null && pubLogFile instanceof File) {
      return URL.createObjectURL(pubLogFile);
    } else {
      if (folderInfo?.publisherIcon) {
        return `${backend_baseUrl}/download/${folderInfo?.publisherIcon.file_Path}`;
      }
    }
    return undefined;
  }, [folderInfo, pubLogFile]);
  const onConfirmDelete = () => {
    const msg = `Are you sure to delete the <b>${folderInfo?.name}</b>?`;
    setShowMsg(msg);
    setRemoveShow(true);
  };
  const onPubLogoImageSet = (idx: number, vFile: File) => {
    setPubLogFile(vFile);
  };
  const renderFileName = (_item: IFileItem) => {
    return _item instanceof File ? _item.name : _item || "";
  };
  return (
    <MainLayout>
      <div className="top_blue_action_bar  py-2 fnt_robo">
        <div className="container d-flex justify-content-center">
          <button
            className="back_btn"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={white_arrow} alt="" /> back
          </button>
          <h4 className="title">
            {isAdded ? `create a ${type}` : `edit ${type}`}
          </h4>
        </div>
      </div>
      <div className="edit_box mx-auto py-3 d-grid gap-2">
        <div className="row">
          <div className="col">
            <Form.Group className="fnt_robo">
              <Form.Label>
                <b>{type} name *</b>
                <span className="d-block">the title of your {type}</span>
              </Form.Label>

              <Form.Control
                as="textarea"
                rows={1}
                {...register("name", { required: true })}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col">
          <Form.Group className="mb-3 fnt_robo">
              <Form.Label>
                <b>tags * </b>
                <span className="d-block">
                  input at least one key tag
                </span>
              </Form.Label>
              {keyTags.map((it, idx) => (
                <div className="d-flex mb-2" key={idx}>
                  <div
                    className="d-flex align-items-center me-1"
                    style={{ width: "25px" }}
                  >
                    {idx + 1}.
                  </div>
                  <Form.Control
                    type="text"
                    className="mt-0"
                    key={idx}
                    value={it}
                    onChange={(e) => {
                      const newItem = [...keyTags];
                      newItem[idx] = e.target.value;
                      setKeyTags(newItem);
                    }}
                  />
                  <div style={{ width: "25px" }}>
                    {idx > 0 && (
                      <img
                        src={minus_icon}
                        alt=""
                        className="ms-2"
                        onClick={() => {
                          removeId.current = idx;
                          setRemoveKeyShow(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </Form.Group>
            <div className="d-flex justify-content-center">
              <img
                src={plus_icon}
                alt=""
                className="cursor"
                onClick={() => {
                  const nwItems = [...keyTags];
                  nwItems.push("");
                  setKeyTags(nwItems);
                  // setRemoveKeyShow(true);
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <b className=" fnt_robo">folder description</b>
          <SunEditor
            height={"250"}
            name="preparation_val"
            setOptions={{
              plugins: plugins,
              buttonList: [
                ["undo", "redo"],
                ["formatBlock"],
                ["blockquote"],
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                ],
                ["table", "list", "link", "image", "video"],
              ],
            }}
            defaultValue={folderInfo?.description || ""}
            onChange={(content) => {
              setValue("description", content);
              // onChange(content, nIndex);
            }}
          />
        </div>
        <div className="d-flex justify-content-center mt-3">
          {folderInfo && (
            <button
              className="remove_btn me-3"
              disabled={!isValid}
              onClick={() => onConfirmDelete()}
            >
              delete {type}
            </button>
          )}
          <button
            className="save_btn"
            onClick={onSave}
            disabled={
              !isValid || keyTags?.filter(item => item.trim() != "").length == 0
            }
          >
            {isSaving && (
              <Spinner animation="border" size={"sm"} className="me-1" />
            )}
            {isAdded ? `create ${type}` : `update ${type}`}{" "}
          </button>
        </div>
      </div>

      {removeShow && (
        <ConfirmRemoveModal
          show={removeShow}
          isDeleting={isDeleting}
          onDiscard={() => {
            if (folderInfo?.id) {
              deleteItem(folderInfo?.id, {
                onSuccess: () => {
                  setRemoveShow(false);
                  navigate(`/dashboard`);
                },
              });
            }
          }}
          onOk={() => {
            setRemoveShow(false);
          }}
          message={showMsg}
        />
      )}
      <ConfirmModal
        show={removeKeyShow}
        content="Are you sure you want to delete the key tag?"
        leftTitle="no"
        rightTitle="yes"
        onDiscard={() => {
          const nwItems = [...keyTags];
          nwItems.splice(removeId.current || 0, 1);
          setKeyTags(nwItems);
          setRemoveKeyShow(false);
        }}
        onOk={() => {
          setRemoveKeyShow(false);
        }}
      />
    </MainLayout>
  );
};
export default EditSubCollection;
