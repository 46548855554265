import React, { PropsWithChildren } from 'react';
import { TopHeader } from './Headers/TopHeader.server';
interface Props {
  wrapClass?: string;
  topClass?: string;
}
export const NoFooterLayout = (props: PropsWithChildren<Props>) => {
  return (
    <div className={props.wrapClass}>
      <TopHeader wrapClass={props.topClass} modalLink={true} />
      <main>{props.children}</main>
    </div>
  );
};
